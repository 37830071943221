import React from "react"

import "./_hero.scss"

const Datenschutz = () => (
  <section className="hero is-small is-link">
    <div className="hero-body">
      <div className="container">
        <h1 className="p-0 m-0">Datenschutz</h1>
      </div>
    </div>
  </section>
)

export default Datenschutz
