import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/privacy"

const PrivacyPage = () => (
  <Layout>
    <Seo title="Unsere Erfahrung" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="content">
          <ol>
            <p>
              Wir freuen uns über Ihr Interesse an Clavicon und Ihrer Website
              unter der URL https://www.clavicon.com {"("}nachfolgend
              {" „"}Website{"“"} genannt{")"}. Nachstehend informieren wir Sie
              ausführlich über den Umgang mit Ihren Daten, die wir im
              Zusammenhang mit dem Betrieb unserer Website erheben.
            </p>
            <li>
              <strong>Verantwortliche</strong> <br />
              Verantwortliche im Sinne des Datenschutzrechts ist die Clavicon
              GmbH {"("}nachfolgend {"„"}Clavicon{"“"} oder {"„"}wir{"“"}{" "}
              genannt{") "}<br />
              Prinzenstraße 14, 30159 Hannover, E-Mailadresse:
              info@clavicon.com.
            </li>
            <li>
              <strong>Aufruf der Website </strong>
              <br />
              Sie können unsere Website besuchen, ohne Angaben zu Ihrer Person
              zu machen. Wir speichern temporär Ihre Zugriffsdaten, die in einer
              Protokolldatei auf unseren Webservern zusammengefasst sind. Zu den
              Zugriffsdaten zählen die folgenden Informationen:
              <ul>
                <li>IP-Adresse des anfragenden Endgeräts,</li>
                <li>Datum und Uhrzeit des Zugriffs,</li>
                <li>Name und URL der abgerufenen Datei,</li>
                <li>Übertragene Datenmenge,</li>
                <li>Meldung, ob der Abruf erfolgreich war,</li>
                <li>
                  Erkennungsdaten des verwendeten Browser- und Betriebssystems,
                </li>
                <li>Webseite, von der aus der Zugriff erfolgt sowie</li>
                <li>Name Ihres Internet-Zugangsproviders.</li>
              </ul>
              Die vorgenannten Daten sind technisch erforderlich, um Ihrem
              Endgerät den Aufruf unserer Website und Nutzung ihrer Funktionen
              zu ermöglichen. Die Erhebung erfolgt zu vorvertraglichen Zwecken
              bzw. in unserem berechtigten Interesse, Ihnen die Inhalte dieser
              Webseite anzuzeigen {"("}Art. 6 Abs. 1 b{")"} und f{")"} DS-GVO
              {")"}.
            </li>
            <li>
              <strong>Cookies</strong>
              <br />
              Wir verwenden keine Cookies.
            </li>
            <li>
              <strong>Kontakt</strong>
              <br />
              Unter dem Link {"„"}Kontakt{"“"} haben wir unsere Daten angegeben,
              über die Sie uns erreichen können. Eine Kontaktaufnahme direkt
              über die Website ist nicht möglich, entsprechend erfolgt auch
              keine Datenerhebung.
            </li>
            <li>
              <strong>
                Gesetzliche und vertragliche Pflichten zur Bereitstellung
              </strong>
              <br />
              Die Bereitstellung Ihrer Daten, wie sie in dieser
              Datenschutzerklärung beschrieben sind, ist weder vertraglich noch
              gesetzlich vorgeschrieben.
            </li>
            <li>
              <strong>Weitergabe Ihrer Daten</strong>
              <br />
              Wir geben Ihre Daten nur an Dritte weiter, wenn wir hierzu
              aufgrund des geltenden Rechts berechtigt oder verpflichtet sind.
              Eine Berechtigung liegt vor, wenn Sie uns eine Einwilligung
              erteilen oder Dritte, die Daten in unserem Auftrag verarbeiten:
              Wenn wir unsere Geschäftstätigkeiten
              {"("}z.B. Betrieb der Website oder Datenanalyse{")"} nicht selbst
              vornehmen, sondern von anderen Unternehmern vornehmen lassen, und
              diese Tätigkeiten mit der Verarbeitung Ihrer Daten verbunden ist,
              haben wir diese Unternehmen zuvor vertraglich verpflichtet, die
              Daten nur für die uns rechtlich erlaubten Zwecke zu verwenden. Wir
              sind befugt, diese Unternehmen insoweit zu kontrollieren. Im
              Einzelfall können wir rechtlich verpflichtet sein,
              personenbezogene Daten an Behörden oder Gerichte weiterzugeben.
              Die damit verbundene Datenverarbeitung beruht folglich auf einer
              rechtlichen Verpflichtung.
            </li>
            <li>
              <strong>Speicherdauer und Löschung von Daten</strong>
              <br />
              Logfiledaten werden 7 Tage nach Erhebung gelöscht.
            </li>
            <li>
              <strong>Ihre Rechte</strong> {"("}Widerspruch, Widerruf, Auskunft,
              Berichtigung, Eiinschränkung der Verarbeitung, Löschung,
              Übertragung, Beschwerde{")"}
            </li>
            <ol>
              <li>
                <strong>Widerspruch</strong>
                <br />
                Sie können der Verarbeitung Sie betreffender personenbezogener
                Daten durch uns aus Gründen, welche sich aus Ihrer besonderen
                Situation ergeben, jederzeit widersprechen. Hierzu können Sie
                die in Ziffer 1 genannten Kontaktmöglichkeiten nutzen. Legen Sie
                Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
                mehr verarbeiten, es sei denn, wir können zwingende
                schutzwürdige Gründe für die Verarbeitung nachweisen, welche
                Ihren Interessen, Rechten und Freiheiten überwiegen.
              </li>
              <li>
                <strong>Widerruf</strong>
                <br />
                Ferner haben Sie das Recht, erteilte Einwilligungen mit Wirkung
                für die Zukunft zu widerrufen. Die Rechtmäßigkeit der
                Datenverarbeitungen, die auf Ihrer Einwilligung beruhen, bleibt
                bis zur Ausübung des Widerrufs hiervon jedoch unberührt.
              </li>
              <li>
                <strong>Sonstige Rechte</strong>
                <br />
                Sie haben jederzeit das Recht, kostenfrei Auskunft über Ihre bei
                uns gespeicherten personenbezogenen Daten zu erhalten,
                unrichtige Daten zu korrigieren sowie Daten in ihrer
                Verarbeitung einschränken oder löschen zu lassen. Ferner haben
                Sie das Recht, Ihre Daten in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten und Ihre Daten durch uns
                auf jemand anderen übertragen zu lassen. Schließlich haben Sie
                das Recht, sich bei einer Datenschutzbehörde zu beschweren. Mit
                Ausnahme Ihres Beschwerderechts bei der Datenschutzbehörde
                können Sie Ihr jeweiliges Anliegen durch Nutzung der in Ziffer 1
                genannten Kontaktdaten an uns richten.
              </li>
            </ol>
            <li>
              <strong>Datensicherheit</strong>
              <br />
              Diese Website ist zum Schutz Ihrer Daten SSL-verschlüsselt.
              Clavicon trifft ferner unter Berücksichtigung des Stands der
              Technik, der Implementierungskosten und der Art, des Umfangs, der
              Umstände und der Zwecke der Verarbeitung sowie der
              Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte
              und Freiheiten natürlicher Personen angemessene technische und
              organisatorische Maßnahmen im Sinne von Art. 32 DS-GVO. Ferner
              erfolgen eine Sicherstellung von Vertraulichkeit, Integrität,
              Verfügbarkeit und Belastbarkeit der Systeme und Dienste im
              Zusammenhang mit der Verarbeitung sowie eine Sicherstellung einer
              raschen Wiederherstellung der Verfügbarkeit von personenbezogenen
              Daten bei einem physischen oder technischen Zwischenfall. Bitte
              berücksichtigen Sie, dass wir uns zwar bemühen, eine sicheren und
              zuverlässige Website für Nutzer zu gestalten, die absolute
              Vertraulichkeit von Mitteilungen oder Materialien, die an oder von
              unserer Website übermittelt werden, jedoch nicht garantiert werden
              kann.
            </li>
          </ol>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivacyPage
